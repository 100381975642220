body,
button,
dd,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}
a {
  color: #222;
  text-decoration: none;
}
img {
  vertical-align: top;
}
ul,
li {
  list-style: none;
}
body {
  font-size: 20px;
}
